@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit&family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /*font-family: 'Permanent Marker',cursive;*/
  /*font-family: 'Encode Sans Expanded',sans-serif;*/
  /*font-family: 'Kanit', sans-serif;*/
  /*font-family: 'Heebo', sans-serif;*/
  font-family: 'Catamaran', sans-serif;
}

html,
body {
  height: 100%;
  /*background: #75a836;*/

  /*brown*/
  /*background: #a56500;*/

  /*green*/
  background: #ccf381;
  color: white;
}